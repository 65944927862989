<template>
<div id="orderManage">
    <!-- 头部搜索 -->
  <div class="bg-white searchDom">
    <div class="flex align-center justify-end margin-bottom-24 header_search">
      <div class="flex align-center solid margin-right-24 padding-lr-16" style="height:32px;width: 300px;">
        <el-input @input="searKeyword" v-model="sendData.keyword" placeholder="请输入优惠码ID/优惠码查询" suffix-icon="search"></el-input>
        <i style="cursor: pointer;" @click="searKeyword" class="el-icon-search"></i>
      </div>
      <el-button @click="addCoupons" style="height: 32px;width: 100px;padding: 0 10px;color: #FFFFFF;background-color: #4E9F5B;">添加优惠码</el-button>
      <el-button  style="height: 32px;width: 68px;padding: 0;color: #4E9F5B !important;border-color: #4E9F5B;" @click="exportData">导出</el-button>
    </div>
  </div>
  <!--搜索列表-->
  <div class="searchList">
    <ul class="row margin-bottom-16 flex-start">
        <li v-for="(v,i) in dataList" :key="i">
		    <div class="fj_post">
		        <div class="details">
                  <p class="flex justify-between">ID：<span>{{v.coupon_id}}</span></p>
                  <p class="flex justify-between">优惠券名称:<span>{{v.coupon_title}}</span></p>
			            <p class="flex justify-between">类型：<span>{{v.coupon_type|couponType}}</span></p>
                  <p class="flex justify-between">优惠码<span>{{v.coupon_code}}</span></p>
                  <p class="flex justify-between">用户领取有效期<span>{{v.coupon_use_invalid_second/86400}}天</span></p>
                  <p class="flex justify-between">未领取：<span>{{v.coupon_use_count}}</span></p>
                  <p class="flex justify-between">创建时间<span>{{ v.coupon_create_time*1000 | getDate }} </span>
                  </p>
                  <p class="flex justify-between">状态
                      <span v-if="v.coupon_get_total=='0'">未激活</span>
                      <span v-if="v.coupon_get_total=='1'">已激活</span>
                  </p>
                  <p style="position: relative;right:0">
                   <span @click="delCouponCode(v.coupon_id)" style="color: #4E9F5B;cursor:pointer">删除</span>
                  </p>
			    </div>
			</div>
		</li> 
    </ul>
  </div>
  <!--分页-->
  <div class="pageNum flex align-center justify-center" id="pageNum">
    <Pagination
      v-show="rows>1"
      :total="rows"
      @change = "getCouponCode"
      :page.sync= "sendData.page"
      :limit.sync="sendData.limit"/>
  </div>

  <!-- 添加优惠券 -->
  <el-dialog id="couponModel" :visible.sync="showModelCode">

    <div class="flex align-center justify-between">
      <span class="text-bold text-16">添加优惠码</span>
      <i @click="closeShowAddCoupModel" class="el-icon-close text-16 text-gray" style="cursor: pointer;"></i>
    </div>

    <el-form id="form" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px"
      class="demo-ruleForm margin-top-24">
      <el-form-item id="item_" label="优惠码名称:" prop="coupon_title">
        <el-input v-model="ruleForm.coupon_title" placeholder="4-12个字符"></el-input>
      </el-form-item>

      <div class="" style="width: 580px;">
        <el-form-item label="优惠码类型:" prop="coupon_type">
          <el-radio-group @change="chooseType" v-model="ruleForm.coupon_type">
            <el-radio label="1">新人满减券</el-radio>
            <el-radio label="2">新人折扣券</el-radio>
            <el-radio label="3">满减券</el-radio>
            <el-radio label="4">折扣券</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="ruleForm.coupon_type!='2'&&ruleForm.coupon_type!='4'?'规则:':'折扣券:'" required>
          <div class="flex">
            <span>满</span>
            <el-form-item prop="coupon_rule_1">
              <el-input type="number" style="width: 130px;margin: 0 10px;" v-model="ruleForm.coupon_rule_1"
                placeholder="请输入金额">
              </el-input>
            </el-form-item>
            <span>{{ruleForm.coupon_type!='2'&&ruleForm.coupon_type!='4'?'减':'享'}}</span>
            <el-form-item prop="coupon_rule_2">
              <el-input type="number" style="width: 130px;margin: 0 10px;" v-model="ruleForm.coupon_rule_2"
                :placeholder="ruleForm.coupon_type!='2'&&ruleForm.coupon_type!='4'?'满减':'折扣'"></el-input>
            </el-form-item>
          </div>
        </el-form-item>

        <el-form-item label="优惠码数量:" prop="coupon_count">
          <el-input v-model.number="ruleForm.coupon_count" placeholder="请输入优惠码数量"></el-input>
        </el-form-item>
        <el-form-item label="每人限领:" prop="coupon_limit">
          <el-input type="number" v-model="ruleForm.coupon_limit" placeholder="请输入每人限领次数"></el-input>
        </el-form-item>
        
        <el-form-item label="优惠码过期时间:" prop="resource">
          <el-radio-group v-model="lostTime">
            <el-radio label="1">长期有效</el-radio>
            <el-radio label="2">设置过期时间</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="lostTime==2" label="设置过期时间:" prop="coupon_get_invalid_time">
          <el-date-picker @change="changeTime" v-model="ruleForm.coupon_get_invalid_time" type="date" placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="用户领码有效期:" prop="coupon_use_invalid_second">
          <el-input v-model.number="ruleForm.coupon_use_invalid_second" placeholder="请输入卡码领取后多久过期(天) 例如: 30天"></el-input>
        </el-form-item>

        <el-form-item id="item_2" label="适用品类:" prop="coupon_category">
          <el-select v-model="ruleForm.coupon_category" placeholder="请选择适用品类">
            <el-option label="全部" value="0"></el-option>
            <el-option label="文件和图片打印" value="1"></el-option>
            <el-option label="证件照打印" value="2"></el-option>
            <el-option label="照片冲洗" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态:" prop="coupon_state">
          <el-switch active-value="1" inactive-value="0" v-model="ruleForm.coupon_state"></el-switch>
        </el-form-item>
      </div>

    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="submitForm('ruleForm')">保 存</el-button>
    </span>
  </el-dialog>
</div>
</template>
<script>
import {coupon,couponAdd,couponDel} from '@/api/http'
import Pagination from '@/components/pagination'
export default {
    data(){
      var validateNum = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('不能为空'));
        }
        setTimeout(() => {
          if (value < 0) {
            callback(new Error('必须大于0'));
          } else {
            callback();
          }
        }, 300);
      };
      return{
        dataList: [], //表格数据
        /* 分页参数*/
        pages:1, //总页数
        rows:1, //总条数
       /* 查看附件弹窗*/
        sendData:{
          coupon_hide: '1',
          keyword: '',
          page: 1,
          limit: 10
        },
        showModelCode:false,
        type:'',
        sendObj:{},
        couponInfo:{},
        title:'',
        ruleForm: {
          coupon_hide: 1,
          coupon_limit: 1,
          coupon_count: 1,
          coupon_category: 0,
          coupon_state:1
        },
        rules: {
          coupon_title: [{
              required: true,
              message: '请输入优惠码名称',
              trigger: 'blur'
            },
            {
              min: 4,
              max: 12,
              message: '长度在 4 到 12 个字符',
              trigger: 'blur'
            }
          ],
          coupon_type: [{
            required: true,
            message: '请选择优惠码类型',
            trigger: 'change'
          }],
          coupon_rule_1: [{
            required: true,
            trigger: 'blur',
            validator: validateNum
          }],
          coupon_rule_2: [{
            required: true,
            trigger: 'blur',
            validator: validateNum
          }, ],
          count: [{
            required: true,
            trigger: 'blur',
            validator: validateNum
          }, ],
          coupon_get_invalid_time: [{
            required: true,
            message: '请选择过期时间',
            trigger: 'blur'
          }],
          coupon_use_invalid_second: [{
            required: true,
            trigger: 'blur',
            validator: validateNum
          }, ],
        },
        type: '',
        lostTime: '1', //选择优惠券过期时间
        showEditModel:false,
        content:'',
        imgContent:'',
        qaType:[],
        ruleForm:{},
        rules:{},
        input_title:'',
        showAddCoupModel:false,
      }
    },
    created(){
      if(!localStorage.getItem('yhc_coupon_state')){
        localStorage.setItem('yhc_coupon_state','999')
      }
      this.getCouponCode()
    },
    filters: {
      couponType(val) {
        let arr = ['', '新人满减', '新人折扣', '满减', '折扣']
        let state = arr[Number(val)]
        return state
      }
    },
    methods:{ 
      chooseType(e){
          console.log(e)
          this.type = e
      },
      changeInfo(e,type){
          this.sendObj[type] = e
      },  
      exportData(){
        console.log('sss')
        let obj = {
          coupon_hide:'1',
          keyword: localStorage.getItem('yhc_coupon_keyword'),
          coupon_state: localStorage.getItem('yhc_coupon_state'),
          limit: 9999
        }
        coupon(obj).then(res => {
          console.log('优惠券列表', res)
           if (res.data.code == '1' && res.data.csv_file) {
            window.open(res.data.csv_file)
          }
        })
      },
      /* 选择优惠券过期时间*/
      changeTime(e) {
        console.log(e)
        this.time1 = Date.parse(new Date(e+' 00:00:00')) / 1000
      },
      changeValue(e){
        // console.log(this.search)
        // localStorage.setItem('yhc_coupon_keyword',this.search)
        this.sendData.page = 1
        this.getCouponCode()
      },
      //添加优惠券
      addCoupons(){
          this.showModelCode = true
      },
      closeShowAddCoupModel(){
          this.showModelCode = false
      },
      closeBox(){
          this.showEditModel = false
      },
      delCouponCode(id){
        this.$confirm('是否确定删除该优惠券?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          couponDel({coupon_id: id}).then(res=>{
            console.log('删除当前优惠码',res.data)
            // if(res.data.code == '1'){
            //   this.$message.success('删除成功')
            //   this.getCouponCode()
            // }else{
            //   this.$message.error(res.info)
            // }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      /* 提交*/
      submitForm(formName) {
        console.log(this.ruleForm)
        let sendObj = {}
        let that = this
        for (let item in this.ruleForm) {
          sendObj[item] = that.ruleForm[item]
        }
        sendObj.coupon_get_invalid_time = this.time1
        sendObj.coupon_hide = 1
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.sendRequest(formName, sendObj)
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      /* 发送请求*/
      sendRequest(formName, sendObj) {
        couponAdd(sendObj).then(res => {
          console.log('添加优惠码', res)
          if (res.data.code == '1') {
            this.$message.success('添加成功')
            this.$refs[formName].resetFields();
            this.showAddCoupModel = false
            this.getCouponCode()
          } else {
            this.$message.error(res.info)
          }
        })
      },
      //获取意见反馈列表
      getCouponCode(){
        coupon(this.sendData).then(res=>{
          console.log('优惠码列表'+res.data)
          let {list,page,count,limit}=res.data.data
          if(res.data.code == '1' && list){
            this.rows = count
            this.dataList = list
          }else if(res.data.code=='1'&&res.data.data.csv_file){
            window.open(res.data.data.csv_file)
            this.sendData.limit=this.rows
          }
        })
      },
      //搜索
      searKeyword(){
        // this.sendData.page = 1
        // this.getCouponCode()
        console.log(this.sendData.keyword)
        if(!this.sendData.keyword) return this.getCouponCode();
        // return this.dataList.filter(v=>{
        //   console.log(v)
        //   return v.coupon_title.includes(this.sendData.keyword);
        // })
        var _this = this
        var newItems = []
        this.dataList.map(function(item){
          // console.log(item)
          if(!item.coupon_id.search(_this.sendData.keyword) || !item.coupon_code.search(_this.sendData.keyword)){
            console.log(item)
            newItems.push(item)
          }
        })
        // return newItems
        console.log(newItems)
        this.dataList = newItems
      },
      /* 清空输入框 */
      emptyKeyword(e){
        if(e.length=='0'){
          this.sendData.page=1
          this.getCouponCode()
        }
      },
      // 关闭弹窗
      closeEditModel() {
        this.showEditModel = false
        this.getCouponCode()
      },
      closeImgModel() {
        this.showImgModel = false
        this.getCouponCode()
      }
    },
    components:{
      Pagination
    }
}
</script>
<style>
    #orderManage{
  width: 100%;
}
#orderManage .header_search .el-input__inner {
  height: 20px !important;
  border: none !important;
  padding: 0 !important;
} 
#orderManage .searchDom{
    height: 70px;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 24px 80px 4px 24px;
    position: fixed;
    z-index: 20;
    left: 0;
    top: 60px;
    right: 0;
    background: #fff;
    box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 10%);
}
#orderManage .searchList{
  padding: 16px;
  margin-top: 76px;
}  
#orderManage ul{
  list-style: none;
  margin-left: -16px;
  margin-right: 16px;
}
#orderManage .searchList li{
  width: 19%;
  margin-right: 15px;
}
#orderManage ul li .order_number{
  border-bottom: 1px solid #ebedf0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
#orderManage ul li .order h1 {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  color: #323233;
  display: block;
  margin-top: 2px;
}
#orderManage ul li .order font {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  display: block;
  float: right;
}
.order #order_state{
  margin-top: -15px;
}
.order span{
    display: block;
    white-space: nowrap;
    color: #323233;
    font-size: 14px;
    font-weight: bold;
}
.order em{
  display: block;
  white-space: nowrap;
  color: #323233;
  font-size: 14px;
}
.order p {
    color: #323233;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}
.order .order_kd{
  padding: 10px 14px;
  background: rgba(235,237,240,.3);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 20px;
}
.order .order_kd img{
  display: block;
  width: 80px;
  height: 36px;
  object-fit: cover;
  background: #ebedf0;
}
.order .order_kd i{
  display: block;
  width: 1px;
  height: 17px;
  background: #ebedf0;
  margin: 0 16px;
}
.order .order_kd .copy{
  display: block;
  white-space: nowrap;
  color: #79838b;
  font-size: 14px;
}
.pageNum {
    height: 60px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: fixed;
    z-index: 20;
    left: 144px;
    right: 28px;
    bottom: 0;
    background: #fff;
    box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    color: #000;
    font-size: 14px;
}
.fj_post {
    background-color: #ffffff;
    border: 1px solid #f6f6f6;
    border-left: 4px solid transparent;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 30px;
    /**height: 350px;**/
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
    -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
    height: 350px;
}
.fj_post .details{
  margin-top: 45px;
  margin-left: 15px;
}
.fj_post .thumb {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    float: left;
    margin-right: 30px;
    text-align: center;
}

.fj_post .details {
    flex: 1;
}
.fj_post .details p {
    margin-bottom: 10px;
    color: #6d6d6d !important;
    font-size: 14px;
    position: relative;
}
.fj_post .details span{margin-right: 5px;}
.fj_post:hover {
    border: 1px solid #345e3b;
    border-left: 4px solid #345e3b;
}
#couponModel .el-dialog {
    border-radius: 8px;
    width: 780px;
  }

  #couponModel .el-dialog__header {
    display: none !important;
  }

  #couponModel .el-dialog__body {
    padding: 24px !important;
  }

  #couponModel .el-dialog__footer {
    text-align: center !important;
  }

  #couponModel .el-dialog__footer button {
    background-color: #4E9F5B !important;
    color: white;
    width: 260px;
    height: 40px;
  }

  #form #item_.el-form-item {
    border-bottom: 1px solid #F5F6F7 !important;
  }

  #form #item_.el-form-item .el-input__inner {
    border: none !important;
  }

  #form .el-form-item .el-form-item__content {
    text-align: left !important;
  }

  #form #item_2.el-form-item .el-input__inner {
    border: none !important;
    background-color: #F5F6F7 !important;
  }

  #form .el-form-item__label {
    text-align: left !important;
    font-size: 16px !important;
    color: #333333;
    font-weight: bold;
  }
</style>